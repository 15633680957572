<template>
  <div class="container instructions">
    <b-row>
      <b-col lg="8">
        <article class="post-area single-blog">
          <div class="about-me">
            <div class="about-me-text mt-0 pt-0">
              <div class="mt-0 mb-5">
                <h5 class="mt-0">Kazalo vsebine</h5>
                <div>
                  <router-link
                    :to="{ name: 'Instructions', hash: '#o-aplikaciji' }"
                    >O aplikaciji</router-link
                  >
                </div>
                <div>
                  <router-link
                    :to="{ name: 'Instructions', hash: '#kako-namestiti' }"
                    >Kako namestiti aplikacijo
                  </router-link>
                </div>
                <div>
                  <router-link
                    :to="{ name: 'Instructions', hash: '#kako-dodati-zapis' }"
                    >Kako dodati zapis?
                  </router-link>
                </div>
                <div>
                  <router-link
                    :to="{ name: 'Instructions', hash: '#zvrsti-in-podzvrsti' }"
                    >Zvrsti in podzvrsti
                  </router-link>
                </div>
                <div>
                  <router-link
                    :to="{ name: 'Instructions', hash: '#filtriranje' }"
                  >
                    Filtriranje
                  </router-link>
                </div>
<!--                <div>-->
<!--                  <router-link-->
<!--                    :to="{ name: 'Instructions', hash: '#posebne-oznake' }"-->
<!--                    >Posebne oznake-->
<!--                  </router-link>-->
<!--                </div>-->
                <div>
                  <router-link
                    :to="{ name: 'Instructions', hash: '#nacini-uporabe' }"
                    >Načini uporabe
                  </router-link>
                </div>
                <div class="pl-4">
                  <router-link
                    :to="{ name: 'Instructions', hash: '#vir-podatkov' }"
                    >Vir podatkov za etnološke raziskave
                  </router-link>
                </div>
                <div class="pl-4">
                  <router-link
                    :to="{ name: 'Instructions', hash: '#etnoloski-intervju' }"
                    >Etnološki intervju – vsebina je v pripravi
                  </router-link>
                </div>
                <div class="pl-4">
                  <router-link
                    :to="{
                      name: 'Instructions',
                      hash: '#aplikacija-kot-ucni-pripomocek'
                    }"
                    >Aplikacija kot učni pripomoček
                  </router-link>
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'TermsAndConditions',
                      hash: '#pogoji-uporabe'
                    }"
                    >Pogoji uporabe in varstvo zasebnosti
                  </router-link>
                </div>
              </div>

              <h2 id="o-aplikaciji" name="o-aplikaciji">O APLIKACIJI</h2>
              <p>
                Lastnik aplikacije: Zavod Dobra pot (<a
                  href="mailto:info@dobra-pot.si"
                  >info@dobra-pot.si</a
                >)<br />
                Tip aplikacije: napredna spletna aplikacija (PWA)<br />
                Ideja za aplikacijo: Anu Kahuna<br />
                Vodja projekta: Nava Vardjan<br />
                Razvoj: SVON, Marko Zevnik s. p.<br />
                Oblikovanje: Pika-Barbara Ciuha, oblikovalka<br />
                Uredniški odbor: Sitka Tepeh, Nava Vardjan<br />
              </p>

              <iframe class="mt-4" width="560" height="315" src="https://www.youtube.com/embed/cuktfS4VcwY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="width: 100%;"></iframe>

              <h2 id="kako-namestiti" name="kako-namestiti">
                Kako namestiti aplikacijo?
              </h2>
              <p>
                Aplikacija Zapisi spomina je t. i. »napredna spletna aplikacija«
                (PWA - progressive web app). To pomeni, da si jo namestite
                enostavno prek URL povezave tako, da dodate ikono na zaslon
                svoje mobilne ali namizne naprave. Deluje na operacijskih
                sistemih iOs, Android, Windows, MAC, Linux.
              </p>
              <p><b>Namestitev na mobilno napravo:</b></p>
              <ul>
                <li>
                  Odprite URL povezavo aplikacije
                  (http://zapisi-spomina.dobra-pot.si)
                </li>
                <li>
                  Nekatere pametne naprave vas samodejno vprašajo, ali želite
                  namestiti aplikacijo – v tem primeru samo tapnite »Da« in
                  ikonca bo nameščena na začetni zaslon. Če se ne pojavi
                  samodejno vprašanje, odprite nastavitve brskalnika v mobilni
                  napravi.
                </li>
                <li>
                  V nastavitvah se pomaknite navzdol do možnosti »Dodaj na
                  začetni zaslon« (»Add to Home Screen«).
                </li>
                <li>Potrdite tako, da tapnete »Dodaj« (»Add«).</li>
                <li>
                  Na začetnem zaslon boste odslej imeli ikono Zapisi spomina.
                </li>
              </ul>

              <p><b>Namestitev na namizje računalnika:</b></p>
              <ul>
                <li>
                  V brskalniku odprite URL povezavo do aplikacije
                  (http://zapisi-spomina.dobra-pot.si)
                </li>
                <li>
                  Nato čisto desno v orodni vrstici brskalnika kliknite gumb
                  »Namesti« (»Install«).
                </li>
                <li>
                  Ko se odpre okence z vprašanjem, še enkrat kliknite »Namesti«
                  (»Install«).
                </li>
                <li>
                  Na namizju računalnika boste odslej imeli ikono Zapisi
                  spomina.
                </li>
              </ul>

              <h2 id="kako-dodati-zapis" name="kako-dodati-zapis">
                Kako dodati zapis?
              </h2>
              <p><b>Obvezna registracija:</b></p>
              <ul>
                <li>
                  Najprej se morate registrirati. To storite s klikom gumba v
                  zgornjem desnem kotu aplikacije.
                </li>
                <li>
                  Ko vnesete svoje podatke in preberete Pogoje uporabe ter
                  označite, da se z njimi strinjate, boste na elektronski naslov
                  prejeli potrditveno sporočilo s povezavo, ki jo morate
                  klikniti, da se aktivira vaš uporabniški račun.
                </li>
                <li>
                  Potem se prijavite v aplikacijo s svojim uporabniškim imenom
                  (e-naslov) in geslom.
                </li>
              </ul>

              <p><b>Dodajanje zapisa:</b></p>
              <ul>
                <li>
                  Ko ste enkrat registrirani in prijavljeni v aplikacijo, nov
                  zapis dodate s klikom simbola plus v spodnji orodni vrstici na
                  mobilnih napravah oziroma istega simbola na zaslonu namizne
                  naprave.
                </li>
              </ul>

              <p class="pl-4">
                Druga možnost je, da na računalniškem zaslonu v zgornjem desnem
                kotu kliknete svoje uporabniško ime in nato v spustnem seznamu
                možnost »Nov zapis« ali na mobilni napravi zgornjo zeleno
                vrstico, nato svoje uporabniško ime in možnost »Nov zapis«.
              </p>

              <ul>
                <li>
                  Odpre se okno za dodajanje vsebine. Sledite navodilom v
                  posameznem polju. Upoštevajte, da so polja, označena z
                  zvezdico (*), obvezna. Če jih ne izpolnite, vsebine ne boste
                  mogli shraniti in objaviti.
                </li>
                <li>
                  Omogočen je vnos besedila, fotografij, videoposnetkov in
                  zvočnih posnetkov. Vsak zapis mora biti opremljen z naslovom
                  in kratkim povzetkom vsebine, poleg tega mora biti uvrščen v
                  ustrezno kategorijo in regijo.
                </li>
                <li>
                  Po želji lahko vsebino opremite samo z najbolj ključnimi
                  podatki ali pa vpišete podrobnosti o pričevalcu oz. nosilcu
                  tega spomina. Podrobnosti ne bodo javno objavljene, hranili
                  jih bomo v
                  <router-link
                    :to="{ name: 'Instructions', hash: '#vir-podatkov' }"
                    >arhivu</router-link
                  >.
                </li>
                <li>
                  Ko končate z vpisovanjem podatkov, imate dve možnosti –
                  kliknete lahko »Shrani osnutek« ali »Shrani in pošlji«.
                </li>
                <li>
                  Prva možnost pomeni, da se vaša vsebina shrani v obliki
                  osnutka, ki še ne bo objavljen, in ga boste lahko naknadno
                  urejali, dopolnjevali, izbrisali.
                </li>
                <li>
                  Ko enkrat kliknete »Shrani in pošlji« pa to pomeni, da je bil
                  vaš zapis poslan v uredniški pregled in bo v kratkem potrjen,
                  tj. javno objavljen v aplikaciji. Po tem zapisa več ne morete
                  urejati, dopolnjevati ali izbrisati oziroma se morate za to
                  obrniti na upravljavca aplikacije.
                </li>
                <li>
                  Če pri vnašanju naletite na kakršne koli težave, nam to
                  sporočite na info@dobra-pot.si in potrudili se bomo, da jih
                  skupaj razrešimo.
                </li>
              </ul>
              <p>Želimo vam prijetno zapisovanje spominov!</p>

              <h2 id="zvrsti-in-podzvrsti" name="zvrsti-in-podzvrsti">
                Zvrsti in podzvrsti
              </h2>
              <p>
                Po Konvenciji o varovanju nesnovne kulturne dediščine nesnovna
                dediščina pomeni prakse, predstavitve, izražanja, znanja in
                veščine kot tudi inštrumente, predmete, izdelke in kulturne
                prostore povezane z njimi, ki jih skupnosti, skupine in
                posamezniki prepoznavajo kot del svoje kulturne dediščine in se
                na tradicionalen način prenašajo iz roda v rod.
              </p>
              <p>
                Vsebine se v aplikaciji beležijo glede na etnološke zvrsti in
                podzvrsti, kot so določene v Pravilniku o seznamih zvrsti
                dediščine in varstvenih usmeritvah (Uradni list RS, št. 102/10),
                ter glede na geografsko območje, iz katerega prihajajo zapisi.
              </p>
              <p>
                Pri uvrščanju zapisov v ustrezno kategorijo so vam lahko v pomoč
                naslednje smernice:
              </p>
              <p>
                1) <b>Ustno izročilo in ljudsko slovstvo</b> obsega številne
                človekove aktivnosti, povezane z jezikovnim izražanjem kot
                tradicionalnim orodjem za širjenje ljudskega izročila, podatkov
                in znanja. V to zvrst sodijo narečja, pregovori in reki, uganke,
                šaljivke in vici, pesništvo, pripovedništvo ter mitologija in
                bajeslovje.
              </p>
              <p>
                2) <b>Uprizoritve in predstavitve</b> obsegajo predvsem oblike
                glasbenega, pevskega, plesnega ali gledališkega predstavljanja
                in likovnega izražanja, ki se izvajajo z javnim nastopanjem. V
                to zvrst sodijo tudi folklorno turistične prireditve, temelječe
                na sestavinah nesnovne dediščine, ki so sčasoma postale del
                lokalne in nacionalne tradicije. Del uprizoritvene nesnovne
                dediščine so tudi igre, ki jih delimo na otroške, odrasle in
                športne.
              </p>
              <p>
                3) <b>Šege in navade</b> so raznolike aktivnosti s področja
                družbene kulture, ki označujejo in razlikujejo praznične in
                vsakdanje oblike človekovega delovanja, verovanja in vedenja. V
                to zvrst sodijo letne šege, šege življenjskega kroga, delovne in
                pravne šege ter praznovanja v družinskem in skupnostnem okviru.
              </p>
              <p>
                4) <b>Znanja o naravi in okolju</b> so izročila o znanju o
                rastlinskem in živalskem svetu, o vremenu, vodi, prostoru in
                vesolju. Odražajo se kot nesnovna sporočila v človekovih
                izdelkih ali pa so neposredno izpričana v ustnem izročilu. V to
                zvrst uvrščamo tudi zdravilstvo kot preprosto, nestrokovno
                zdravljenje ljudi in domačih živali, temelječe na čarovnih in
                naravnih praksah.
              </p>
              <p>
                5) <b>Gospodarska znanja in veščine</b> so znanje in veščine
                rokodelstva in obrtništva, trgovine in transporta ter znanje in
                delovne prakse nabiralništva, lovstva, kmetijstva (na primer
                poljedelstvo, živinoreja, vinogradništvo, čebelarstvo),
                gozdarstva, rudarstva in industrije. Povezujejo materialno in
                nesnovno dediščino. V to zvrst uvrščamo tudi znanje o hrani, ki
                so preplet različnih pojavnih oblik kulturnih prvin na področjih
                pridelovanja, nakupa in prodaje hrane, priprave in potrošnje
                vsakdanjih, prazničnih ter delovnih jedi.
              </p>
              <p>
                6) <b>Ostalo</b> je zvrst, v katero sodi nesnovna dediščina, ki
                je ni mogoče uvrstiti v nobeno od naštetih zvrsti.
              </p>

              <h2 id="filtriranje" name="filtriranje">Filtriranje</h2>
              <p>
                Aplikacija omogoča, da vsebine po želji filtrirate glede na 1)
                geografsko območje (statistično regijo), iz katere prihajajo
                vsebine, 2) krovnih 6 zvrsti in 3) avtorje zapisa. To storite s
                klikom na regijo, zvrst ali uporabnika.
              </p>

<!--              <h2 id="posebne-oznake" name="posebne-oznake">Posebne oznake</h2>-->
<!--              <p>-->
<!--                Nekateri zapisi so opremljeni s posebnimi oznakami (zelenimi-->
<!--                pečati). S tem so označeni dokumentarni zapisi iz uradnega-->
<!--                Registra nesnovne kulturne dediščine, ki ga upravlja Slovenski-->
<!--                etnografski muzej. Gre torej za enote, ki so bile na državni-->
<!--                ravni prepoznane kot ključen del naše kulturne dediščine. Če-->
<!--                imate v povezavi s temi vsebinami svoje osebne zgodbe in-->
<!--                spomine, vabljeni, da jih vnesete.-->
<!--              </p>-->

              <h2 id="nacini-uporabe" name="nacini-uporabe">Načini uporabe</h2>
              <p>
                <b id="vir-podatkov" name="vir-podatkov"
                  >a.) Vir podatkov za etnološke raziskave</b
                >
              </p>
              <p>
                Poleg podatkov, ki so javno objavljeni, se podrobnejši podatki o
                pričevalcih/nosilcih spomina (v kolikor jih uporabnik vnese)
                shranijo v arhiv. Dostop do teh podatkov po potrebi omogočamo
                znanstvenikom, etnologom in drugim strokovnjakom za namen
                izvajanja zgodovinskih raziskav, etnoloških raziskav oziroma
                raziskav na področju kulturne dediščine, ki služijo splošni
                družbeni dobrobiti, napredku znanosti in razumevanja družbene
                zgodovine, tehnološkemu napredku, javnemu interesu.
              </p>
              <p>
                Če ste raziskovalec in bi želeli dostop do teh podatkov, nam to
                sporočite na info@dobra-pot.si.
              </p>
              <p>
                <b id="etnoloski-intervju" name="etnoloski-intervju"
                  >b.) Etnološki intervju</b
                >
                Če potrebujete nekaj napotkov o tem, kako opraviti pogovor s starejšimi, da bo vsebina čim bolj relevantna za vnos v aplikacijo, si oglejte <a style="color: #6d5a3f" href="https://www.dobra-pot.si/assets/dobra-pot/documents/Smernice%20za%20intervju%20ZS.pdf" target="_blank">Smernice za strukturiran intervju</a>. Pripravili smo ji v sodelovanju s strokovno sodelavko iz Javnega zavoda Park Škocjanske jame, Slovenija.
              </p>
              <p>
                <b
                  id="aplikacija-kot-ucni-pripomocek"
                  name="aplikacija-kot-ucni-pripomocek"
                  >c.) Aplikacija kot učni pripomoček</b
                >
              </p>
              <p>
                Aplikacijo lahko uporabljate na delavnicah, v šolah ipd. <a style="color: #6d5a3f" href="https://www.dobra-pot.si/assets/dobra-pot/documents/Ucni_listi_ZS_1.pdf" target="_blank">Oglejte si nekaj načinov</a>, kako Zapise spomina uporabiti kot učni pripomoček.
              </p>
            </div>
          </div>
        </article>
      </b-col>
      <b-col lg="4">
        <Sidebar></Sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";
import Sidebar from "@/components/Sidebar";

export default {
  name: "Instructions",
  components: {
    Sidebar,
  },
  data() {
    return {
      users: [],
    };
  },
  metaInfo() {
    return {
      title: "Navodila"
    };
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
<style type="text/css" scoped>
* {
  color: #6d5a3f;
}

.instructions {
  text-align: justify;
}

a {
  color: #69a637 !important;
}

h2 {
  font-size: 18px;
  margin: 40px 0px 20px 0px;
}

h3 {
  font-size: 15px;
  margin: 20px 0px;
}

p {
  margin-bottom: 10px !important;
  font-size: 14px;
  line-height: 20px;
}

ul {
  list-style-type: circle;
}

li {
  font-size: 14px;
}
</style>
